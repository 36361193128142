@import "../../assets/scss/abstract/abstract";

.carDetails {
  display: flex;
  flex-direction: column;

  &__set {
    border-bottom: 1px solid var(--yps-border-color-light);
    padding: 0 0 10px;
    margin: 0 0 10px;
  }

  &__legend {
    text-transform: uppercase;
    display: block;
    margin: 5px 0;
    font-weight: 600;
  }

  &__detail {
    margin: 0 0 10px;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto auto;
    @include tablet {
      grid-template-columns: auto auto;
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    padding: 5px 0;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    &_value {
      font-weight: 600;
    }

    &_description {
      width: 100%;
    }

    &_product_price {
      margin-left: 5px;
    }
  }

  &__arroundLogo {
    padding: 0 0 5px;
    img {
      padding: 10px;
      border: 1px solid var(--yps-border-color-light);
    }
  }

  &__simpleLogo {
    padding: 0 0 5px;
  }

  &__terms_container {
    max-height: 500px;
    overflow-y: auto;
  }

  &__termsButton {
    text-align: center;
    margin: 20px;
  }

  &__iframe {
    width: 100%;
    min-height: 470px;
    border: 1px solid var(--yps-border-color-light);
  }

  &__infobox {
    margin-top: 15px;
  }
}

.stationsTab {
  display: flex;
  column-gap: 15px;

  &__main {
    flex: 1;
  }

  &__map {
    width: 300px;
    display: grid;
    grid-template-rows: 1fr 30px;
    row-gap: 15px;
  }

  &__variantList {
    @include resetList();
    max-height: 300px;
    overflow: auto;
  }

  &__variant {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;

    &:hover {
      background-color: var(--yps-bg-color-secondary);
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }

    &__icon {
      color: var(--yps-text-color-extra-light);
    }
  }

  &__station {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;

    &__main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 5px;
    }

    &__tickIcon {
      color: var(--yps-success-color);
    }

    &__icon {
      color: var(--yps-text-color-extra-light);

      &--current {
        color: var(--yps-success-color);
      }
    }

    &__name {
      display: block;
      font-size: 13px;
      line-height: 13px;
      font-weight: 600;
      margin: 0 0 3px 0;
    }

    &__address {
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: var(--yps-text-color);
      margin: 0;
    }
  }

  &__bookBtn {
    padding: 10px;
    display: flex;
    align-items: center;

    span {
      margin: 0;
      padding: 0;
    }

    &--highlight {
      background-color: var(--yps-warn-color);
      border-color: var(--yps-warn-color);
    }
  }
}
